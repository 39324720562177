<template>
  <AtomDashHeader :is-loading="isLoading" :title="title" :action-title="actionTitle" @action="buildForm()">
    <div
      v-if="!isLoading && numPhotographs"
      class="flex items-center text-black text-2xl font-bold tracking-tight leading-tight"
    >
      <BaseIcon class="mx-2"><ArrowIcon /></BaseIcon>
      <span>{{ numPhotographs }}</span>
    </div>
  </AtomDashHeader>

  <!-- Tagged Photographs -->
  <div v-if="!isLoading && hasTaggedPhotographs" class="mt-4 pb-4 px-4 border-b border-gray lg:mt-6 lg:px-8">
    <div class="mx-auto max-w-screen-2xl">
      <h3 class="flex items-center text-lg">
        You're tagged in
        <!-- ({{ taggedTotal }}) -->
      </h3>
      <div class="grid gap-2 grid-cols-1 mt-2 lg:grid-cols-2">
        <template v-for="(photograph, index) in taggedPhotographs" :key="photograph.uid">
          <AtomPhotograph
            :photograph="photograph"
            :width="index === 0 ? 1560 : 800"
            :classes="[index === 0 ? 'lg:col-span-2' : null]"
            hide-album-actions
            hide-tag-action
            :link="true"
            @menu="toggleMenus"
          />
        </template>
      </div>
      <button
        v-if="taggedTotal > taggedVisible"
        type="submit"
        :class="[
          'flex mx-auto mt-4 items-center justify-center border border-gray rounded-sm w-full lg:w-auto py-2 px-4 transition-colors ease-in-out duration-300 lg:hover:bg-orange-500 lg:hover:text-white lg:hover:border-orange-500 focus:outline-none focus:ring-orange-500 focus:border-orange-500',
          !isLoadingNextTaggedPage ? null : 'cursor-default'
        ]"
        @click.prevent="getNextTaggedPage()"
      >
        <span v-if="!isLoadingNextTaggedPage" class="pointer-events-none"> Show more </span>
        <AtomLoader v-else></AtomLoader>
      </button>
    </div>
  </div>

  <!-- Liked Photographs -->
  <div v-if="!isLoading && hasLikedPhotographs" class="mt-4 pb-4 px-4 border-b border-gray lg:mt-6 lg:px-8">
    <div class="mx-auto max-w-screen-2xl">
      <h3 class="flex items-center text-lg">You've liked</h3>
      <div class="grid gap-2 grid-cols-1 mt-2 lg:grid-cols-2">
        <template v-for="(photograph, index) in likedPhotographs" :key="photograph.uid">
          <AtomPhotograph
            :photograph="photograph"
            :width="index === 0 ? 1560 : 800"
            :classes="[index === 0 ? 'lg:col-span-2' : null]"
            hide-album-actions
            hide-tag-action
            :link="true"
            @menu="toggleMenus"
          />
        </template>
      </div>
      <button
        v-if="likedTotal > likedVisible"
        type="submit"
        :class="[
          'flex mx-auto mt-4 items-center justify-center border border-gray rounded-sm w-full lg:w-auto py-2 px-4 transition-colors ease-in-out duration-300 lg:hover:bg-orange-500 lg:hover:text-white lg:hover:border-orange-500 focus:outline-none focus:ring-orange-500 focus:border-orange-500',
          !isLoadingNextLikedPage ? null : 'cursor-default'
        ]"
        @click.prevent="getNextLikedPage()"
      >
        <span v-if="!isLoadingNextLikedPage" class="pointer-events-none"> Show more </span>
        <AtomLoader v-else></AtomLoader>
      </button>
    </div>
  </div>

  <!-- Popular Photographs -->
  <div v-if="!isLoading && hasPopularPhotographs" class="mt-4 pb-4 px-4 border-b border-gray lg:mt-6 lg:px-8">
    <div class="mx-auto max-w-screen-2xl">
      <h3 class="px-4 text-lg md:px-0">Your most liked</h3>
      <div class="grid gap-2 grid-cols-1 mt-2 lg:grid-cols-2">
        <template v-for="(photograph, index) in popularPhotographs" :key="photograph.uid">
          <AtomPhotograph
            :photograph="photograph"
            :width="index === 0 ? 1560 : 800"
            :classes="[index === 0 ? 'lg:col-span-2' : null]"
            hide-album-actions
            :link="true"
          />
        </template>
      </div>
      <button
        v-if="totalPhotographs > popularVisible"
        type="submit"
        :class="[
          'flex mx-auto mt-4 items-center justify-center border border-gray rounded-sm w-full lg:w-auto py-2 px-4 transition-colors ease-in-out duration-300 lg:hover:bg-orange-500 lg:hover:text-white lg:hover:border-orange-500 focus:outline-none focus:ring-orange-500 focus:border-orange-500',
          !isLoadingNextPopularPage ? null : 'cursor-default'
        ]"
        @click.prevent="getNextPopularPage()"
      >
        <span v-if="!isLoadingNextPopularPage" class="pointer-events-none"> Show more </span>
        <AtomLoader v-else></AtomLoader>
      </button>
    </div>
  </div>

  <!-- Albums -->
  <div v-if="!isLoading && hasAlbums" class="my-4 px-4 lg:my-8 lg:px-8">
    <div class="mx-auto max-w-screen-2xl">
      <h3 class="px-4 text-lg md:px-0">Your Albums</h3>
      <div class="grid gap-4 grid-cols-1 2xl:grid-cols-3 mt-2 lg:grid-cols-2">
        <template v-for="album in albums" :key="album.uid">
          <MoleculeAlbum :album="album" on-dashboard @deleted="updateAlbumList" />
        </template>
      </div>
    </div>
  </div>

  <!-- Empty State -->
  <div
    v-if="!isLoading && !hasAlbums && !hasTaggedPhotographs && !hasLikedPhotographs"
    class="mt-4 md:px-4 lg:mt-8 lg:px-8"
  >
    <div class="mx-auto max-w-screen-2xl">
      <div class="px-4 text-lg space-y-4 md:px-0">
        <ul class="max-w-screen-sm space-y-2 lg:space-y-4">
          <li class="flex items-start">
            <BaseIcon class="mr-2 transform translate-y-0.5"><CheckIcon /></BaseIcon>
            <p class="flex-1">
              Looking for an events official photographs?
              <RouterLink
                to="/events"
                class="
                  lg:hover:text-orange-500
                  lg:hover:border-orange-500
                  inline-block
                  leading-normal
                  border-b border-black
                  transition-colors
                  duration-150
                  ease-in-out
                "
              >
                Find them in albums</RouterLink
              >.
            </p>
          </li>
          <li class="flex items-start">
            <BaseIcon class="mr-2 transform translate-y-0.5"><CheckIcon /></BaseIcon>
            <p class="flex-1">
              You can
              <button
                class="
                  lg:hover:text-orange-500
                  lg:hover:border-orange-500
                  inline-block
                  leading-normal
                  border-b border-black
                  focus:outline-none
                  focus:shadow-none
                  transition-colors
                  duration-150
                  ease-in-out
                "
                @click.prevent="buildForm()"
              >
                create your own album
              </button>
              if you've got any good track day photos.
            </p>
          </li>
          <li class="flex items-start">
            <BaseIcon class="mr-2 transform translate-y-0.5"><CheckIcon /></BaseIcon>
            <p class="flex-1">
              Tag yourself in photographs to save them to your dashboard and display them on your profile.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        title: 'Photographs',
        actionTitle: 'Create an album',
        isLoading: null,
        isLoadingNextPopularPage: null,
        isLoadingNextTaggedPage: null,
        isActionsVisible: null,
        albums: null,
        taggedPhotographs: null,
        taggedTotal: null,
        taggedPage: 1,
        taggedOffset: 0,
        taggedVisible: 0,
        likedPhotographs: null,
        likedTotal: null,
        likedPage: 1,
        likedOffset: 0,
        likedVisible: 0,
        popularPage: 1,
        popularOffset: 0,
        popularVisible: 0,
        perPage: 9,
        form: {
          cta: 'Create album',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'name',
              label: 'Album name',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'event_uid',
              label: 'Event',
              elem: 'select',
              isRequired: null,
              classes: 'sm:col-span-2',
              children: [
                {
                  tag: 'option',
                  text: 'None',
                  value: 'none'
                }
              ],
              instruction: {
                content: "Can't see the event you've attended?",
                url: '/events',
                cta: 'You can add a past event.'
              }
            },
            {
              id: 'date',
              label: 'Date taken',
              elem: 'input',
              type: 'date',
              min: '2000-01-01',
              max: `${new Date().getFullYear()}-12-31`,
              isRequired: 'true',
              classes: 'sm:col-span-2'
            }
          ]
        },
        jwt: null
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser',
        today: 'getToday'
      }),
      hasAlbums() {
        return !this.isLoading && this.albums && this.albums.length > 0
      },
      numPhotographs() {
        return String(this.totalPhotographs).padStart(3, '0')
      },
      hasTaggedPhotographs() {
        return !this.isLoading && this.taggedPhotographs && this.taggedPhotographs.length > 0
      },
      hasLikedPhotographs() {
        return !this.isLoading && this.likedPhotographs && this.likedPhotographs.length > 0
      },
      hasPopularPhotographs() {
        return !this.isLoading && this.popularPhotographs && this.popularPhotographs.length > 0
      },
      totalPhotographs() {
        return this.loggedInUser ? this.loggedInUser.num_photographs : 0
      }
    },
    watch: {
      '$route.query.u'() {
        if (this.$route.query && this.$route.query.u) this.showPhotograph(this.$route.query.u)
      }
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        this.isLoading = true
        await Promise.all([
          this.getUserTaggedPhotographs(),
          this.getUserLikedPhotographs(),
          this.getUserPopularPhotographs(),
          this.getUserAlbums()
        ])
        if (this.$route.query.u) this.showPhotograph(this.$route.query.u)
        if (this.$route.query.p) await this.buildForm()
        this.isLoading = false
      },
      async getUserAlbums() {
        try {
          this.albums = await this.apiRequest(`/user/${this.loggedInUser.id}/albums`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting albums: ${e.status} ${e.data}`)
        }
      },
      async getUserPopularPhotographs() {
        try {
          this.popularPhotographs = await this.apiRequest(
            `/user/${this.loggedInUser.id}/photographs`,
            null,
            { page: this.popularPage, perPage: this.perPage },
            {
              Authorization: this.userJWT
            }
          )

          if (!!!this.popularPhotographs.length) return

          this.popularOffset = parseInt(this.popularPhotographs[this.popularPhotographs.length - 1].id) + 1
          this.popularPage += 1
          this.popularVisible = this.totalPhotographs > this.perPage ? this.perPage : this.totalPhotographs
        } catch (e) {
          if (e) console.log(`Error getting users popular photographs: ${e.status} ${e.data}`)
        }
      },
      async getUserTaggedPhotographs() {
        try {
          this.taggedTotal = await this.apiRequest(`/user/${this.loggedInUser.id}/tagged/total`, null, null, {
            Authorization: this.userJWT
          })

          if (this.taggedTotal > 0) {
            this.taggedPhotographs = await this.apiRequest(
              `/user/${this.loggedInUser.id}/tagged`,
              null,
              { page: this.taggedPage, perPage: this.perPage },
              {
                Authorization: this.userJWT
              }
            )

            this.taggedOffset = parseInt(this.taggedPhotographs[this.taggedPhotographs.length - 1].id) + 1
            this.taggedPage += 1
          }

          this.taggedVisible = this.taggedTotal > this.perPage ? this.perPage : this.taggedTotal
        } catch (e) {
          if (e) console.log(`Error getting users tagged photographs: ${e.status} ${e.data}`)
        }
      },
      async getNextTaggedPage() {
        const cta = event.target

        if (cta && cta.style) cta.style.width = `${cta.offsetWidth}px`
        this.isLoadingNextTaggedPage = true

        try {
          const photographs = await this.apiRequest(
            `/user/${this.loggedInUser.id}/tagged`,
            null,
            { page: this.taggedPage, perPage: this.perPage + 1 },
            {
              Authorization: this.userJWT
            }
          )

          this.taggedPhotographs = this.taggedPhotographs.concat(photographs)
          this.taggedOffset = parseInt(this.taggedPhotographs[this.taggedPhotographs.length - 1].id) + 1
          this.taggedPage += 1
        } catch (e) {
          if (e) console.log(`Error getting next tagged photographs: ${e.status} ${e.data}`)
        }

        this.taggedVisible += this.perPage
        if (cta && cta.style) cta.style.width = null
        this.isLoadingNextTaggedPage = false
      },

      async getUserLikedPhotographs() {
        try {
          this.likedTotal = await this.apiRequest(`/user/${this.loggedInUser.id}/liked/total`, null, null, {
            Authorization: this.userJWT
          })

          if (this.likedTotal > 0) {
            this.likedPhotographs = await this.apiRequest(
              `/user/${this.loggedInUser.id}/liked`,
              null,
              { page: this.likedPage, perPage: this.perPage },
              {
                Authorization: this.userJWT
              }
            )

            this.likedOffset = parseInt(this.likedPhotographs[this.likedPhotographs.length - 1].id) + 1
            this.likedPage += 1
          }

          this.likedVisible = this.likedTotal > this.perPage ? this.perPage : this.likedTotal
        } catch (e) {
          if (e) console.log(`Error getting users liked photographs: ${e.status} ${e.data}`)
        }
      },
      async getNextLikedPage() {
        const cta = event.target

        if (cta && cta.style) cta.style.width = `${cta.offsetWidth}px`
        this.isLoadingNextLikedPage = true

        try {
          const photographs = await this.apiRequest(
            `/user/${this.loggedInUser.id}/liked`,
            null,
            { page: this.likedPage, perPage: this.perPage + 1 },
            {
              Authorization: this.userJWT
            }
          )

          this.likedPhotographs = this.likedPhotographs.concat(photographs)
          this.likedOffset = parseInt(this.likedPhotographs[this.likedPhotographs.length - 1].id) + 1
          this.likedPage += 1
          console.log(this.likedPhotographs)
        } catch (e) {
          if (e) console.log(`Error getting next liked photographs: ${e.status} ${e.data}`)
        }

        this.likedVisible += this.perPage
        if (cta && cta.style) cta.style.width = null
        this.isLoadingNextLikedPage = false
      },

      async getNextPopularPage() {
        const cta = event.target

        if (cta && cta.style) cta.style.width = `${cta.offsetWidth}px`
        this.isLoadingNextPopularPage = true

        try {
          const photographs = await this.apiRequest(
            `/user/${this.loggedInUser.id}/photographs`,
            null,
            { page: this.popularPage, perPage: this.perPage + 1 },
            {
              Authorization: this.userJWT
            }
          )

          this.popularPhotographs = this.popularPhotographs.concat(photographs)
          this.popularOffset = parseInt(this.popularPhotographs[this.popularPhotographs.length - 1].id) + 1
          this.popularPage += 1
        } catch (e) {
          if (e) console.log(`Error getting next popular photographs: ${e.status} ${e.data}`)
        }

        this.popularVisible += this.perPage
        if (cta && cta.style) cta.style.width = null
        this.isLoadingNextPopularPage = false
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        if (this.loggedInUser.admin) {
          this.form.fields.push({
            id: 'flickrUrl',
            label: 'Flickr Album URL',
            elem: 'input',
            type: 'text',
            isRequired: null,
            classes: 'sm:col-span-2',
            instruction: {
              content: 'If you would like to automatically import photographs from a Flickr album.'
            }
          })
          this.form.fields.push({
            id: 'eventAlbum',
            label: 'This is an official event album',
            elem: 'input',
            type: 'checkbox',
            isRequired: null,
            classes: 'sm:col-span-2 flex justify-end flex-row-reverse'
          })
        }

        await Promise.all([this.getEvents()])

        this.setEvents()
        this.setMaxDate()

        if (this.$route.query.p) {
          let queries = JSON.parse(JSON.stringify(this.$route.query))
          queries.p = undefined

          this.$router.replace({ query: queries })
        }

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          endpoint: '/album',
          errorMessage: "Sorry we couldn't add the album.",
          successMessage: 'Your album has been added.'
        })
      },
      async getEvents() {
        try {
          this.events = await this.apiRequest(
            `/user/${this.loggedInUser.id}/events`,
            null,
            { basic: true, start: '2000-01-01', end: this.today },
            {
              Authorization: this.userJWT
            }
          )
        } catch (e) {
          if (e) console.log('Error getEvents')
        }
      },
      async setEvents() {
        const eventFormField = this.form.fields.find((field) => field.id === 'event_uid')
        const pastEvents = this.events.filter((event) => {
          return Date.parse(event.start_date) <= Date.now() ? event : null
        })
        const re = /(\d{4})[-. \/](\d\d)[-. \/](\d\d)/

        pastEvents.forEach((event) => {
          const date = event.start_date.slice(0, 10).replace(re, '$3/$2/$1')
          let text = `${date} - ${event.circuit} `

          if (event.slug.includes('evening')) text += `- Evening `
          text += `(${event.organiser})`

          eventFormField.children.push({
            tag: 'option',
            text,
            value: event.uid
          })
        })
      },
      async setMaxDate() {
        const date = this.form.fields.find((field) => field.id === 'date')

        date.max = this.today
      },
      async updateAlbumList(albumUid) {
        this.albums = this.albums.filter((album) => {
          return album.uid !== albumUid
        })
      },
      async showPhotograph(photographUid) {
        try {
          const photograph = await this.apiRequest(`/photograph/${photographUid}`, null, null, {
            Authorization: this.userJWT
          })

          this.$store.dispatch('setOverlay', {
            imagePath: `${process.env.VUE_APP_IMGIX}/${photograph.path}?w=1920&ar=1.5`,
            uid: photographUid,
            photograph_uid: photographUid,
            url: photographUid,
            liked: photograph.liked,
            owner_uid: photograph.owner_uid,
            organisers_album: photograph.organisers_album
          })
        } catch (e) {
          if (e) console.log(`Error getting photograph: ${e.status} ${e.data}`)
        }
      },
      toggleMenus(menuOpen) {
        // if (menuOpen) console.log('Open Menu')
      }
    }
  }
</script>
