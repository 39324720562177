<template>
  <AtomDashHeader :is-loading="isLoading" :title="title" :action-title="actionTitle" @action="buildForm()">
    <div
      v-if="!isLoading && hasEvents"
      class="flex items-center text-black text-2xl font-bold tracking-tight leading-tight"
    >
      <BaseIcon class="mx-1"><ArrowIcon /></BaseIcon>
      <span>{{ numEvents }}</span>
    </div>
  </AtomDashHeader>

  <!-- Events -->
  <div v-if="!isLoading && hasEvents" class="mt-4 lg:mt-8">
    <!-- Upcoming Events -->
    <div v-if="upcomingEvents" class="mt-4 md:px-4 lg:px-8">
      <div class="mx-auto max-w-screen-2xl">
        <h3 class="px-4 text-lg md:px-0">Upcoming</h3>
      </div>
    </div>
    <OrganismEvents :events="upcomingEvents" simple />

    <!-- Past Events -->
    <div v-if="!isLoading && pastEvents" class="mt-4 md:px-4 lg:mt-8 lg:px-8">
      <div class="mx-auto max-w-screen-2xl">
        <h3 class="px-4 text-lg md:px-0">Past</h3>
      </div>
    </div>
    <OrganismEvents :events="pastEvents" full simple />
  </div>

  <!-- Empty State -->
  <div v-if="!isLoading && !hasEvents" class="mt-4 md:px-4 lg:mt-8 lg:px-8">
    <div class="mx-auto max-w-screen-2xl">
      <div class="px-4 text-lg md:px-0">
        <ul class="max-w-screen-sm list-disc space-y-2 lg:space-y-4">
          <li class="flex items-start">
            <BaseIcon class="mr-2 transform translate-y-0.5"><CheckIcon /></BaseIcon>
            <p class="flex-1">
              Got a track day booked?
              <RouterLink
                to="/events"
                class="
                  lg:hover:text-orange-500
                  lg:hover:border-orange-500
                  inline-block
                  leading-normal
                  border-b border-black
                  transition-colors
                  duration-150
                  ease-in-out
                "
              >
                Find it in events</RouterLink
              >
              and let everyone know that you're going.
            </p>
          </li>
          <li class="flex items-start">
            <BaseIcon class="mr-2 transform translate-y-0.5"><CheckIcon /></BaseIcon>
            <p class="flex-1">
              Want to add an a pre-{{ currentYear }} event? You can do that by
              <button
                class="
                  lg:hover:text-orange-500
                  lg:hover:border-orange-500
                  inline-block
                  leading-normal
                  border-b border-black
                  focus:outline-none
                  focus:shadow-none
                  transition-colors
                  duration-150
                  ease-in-out
                "
                @click.prevent="buildForm()"
              >
                adding a past event</button
              >.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    emits: ['is-loading'],
    data() {
      return {
        title: 'Events',
        isLoading: null,
        events: null,
        pastEvents: null,
        upcomingEvents: null,
        form: {
          cta: 'Add event',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'circuit_uid',
              label: 'Circuit',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'layout_uid',
              label: 'Layout',
              elem: 'select',
              isRequired: 'true',
              children: [],
              disabled: true
            },
            {
              id: 'organiser_uid',
              label: 'Organiser',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'format_uid',
              label: 'Format',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'start_date',
              label: 'Date',
              elem: 'input',
              type: 'date',
              min: '2000-01-01',
              max: null,
              isRequired: 'true'
            },
            {
              id: 'url',
              label: 'Event URL',
              elem: 'input',
              type: 'text',
              isRequired: null
            },
            {
              id: 'event_evening',
              label: 'Evening event?',
              elem: 'input',
              type: 'checkbox',
              isRequired: null,
              classes: 'sm:col-span-2 flex justify-end flex-row-reverse'
            }
          ]
        },
        currentYear: new Date().getFullYear(),
        jwt: null
      }
    },
    computed: {
      actionTitle() {
        return this.loggedInUser && this.loggedInUser.admin ? 'Add an event' : 'Add a past event'
      },
      hasEvents() {
        return this.events && !!this.events.length
      },
      numEvents() {
        return String(this.events.length).padStart(3, '0')
      },
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        loggedInUser: 'auth/getLoggedInUser',
        circuits: 'circuits/getCircuits',
        organisers: 'organisers/getOrganisers',
        formats: 'formats/getFormats',
        today: 'getToday'
      })
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        this.isLoading = true
        await this.getEvents()
        if (this.$route.query.p) await this.buildForm()
        this.isLoading = false
      },
      async getEvents() {
        try {
          this.events = await this.apiRequest(`/user/${this.loggedInUser.id}/events`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting users events: ${e.status} ${e.data}`)
        }

        if (this.events) this.setUserEvents()
      },
      async setUserEvents() {
        this.pastEvents = this.events.filter((event) => {
          return Date.parse(event.start_date) <= Date.now() ? event : false
        })

        this.upcomingEvents = this.events
          .filter((event) => {
            return Date.parse(event.start_date) - Date.parse(this.today) < 0 ? false : event
          })
          .reverse()
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        await Promise.all([
          this.$store.dispatch('circuits/getCircuits'),
          this.$store.dispatch('organisers/getOrganisers'),
          this.$store.dispatch('formats/getFormats')
        ])

        this.setCircuits()
        this.setOrganisers()
        this.setFormats()

        const start_date = this.form.fields.find((field) => field.id === 'start_date')
        start_date.max = this.loggedInUser.admin ? '2030-12-31' : this.today

        if (this.$route.query.p) {
          let queries = JSON.parse(JSON.stringify(this.$route.query))
          queries.p = undefined

          this.$router.replace({ query: queries })
        }

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          endpoint: '/event',
          errorMessage: "Sorry we couldn't add the event."
        })
      },
      async setCircuits() {
        const circuitFormField = this.form.fields.find((field) => field.id === 'circuit_uid')

        this.circuits.forEach((circuit) => {
          circuitFormField.children.push({
            tag: 'option',
            text: circuit.name,
            value: circuit.uid
          })
        })
      },
      async setOrganisers() {
        const organiserFormField = this.form.fields.find((field) => field.id === 'organiser_uid')

        this.organisers.forEach((organiser) => {
          organiserFormField.children.push({
            tag: 'option',
            text: organiser.name,
            value: organiser.uid
          })
        })
      },
      async setFormats() {
        const formatFormField = this.form.fields.find((field) => field.id === 'format_uid')

        this.formats.forEach((format) => {
          formatFormField.children.push({
            tag: 'option',
            text: format.name,
            value: format.uid
          })
        })
      }
    }
  }
</script>
