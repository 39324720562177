<template>
  <AtomDashHeader :is-loading="isLoading" :title="title" :action-title="actionTitle" @action="buildForm()" />
</template>

<script>
  export default {
    data() {
      return {
        title: 'Stats',
        actionTitle: 'Update stats'
      }
    }
  }
</script>
