<template>
  <!-- Feed -->
  <div v-if="!isLoading && hasFeedItems" class="mb-4 px-4 md:mt-2 lg:px-8">
    <div class="w-full md:max-w-screen-sm">
      <div class="grid gap-4 grid-cols-1">
        <MoleculeFeed v-for="item in feed" :key="item.uid" :item="item" />
      </div>
    </div>
  </div>
  <!-- Empty State -->
  <div v-else-if="!isLoading && !hasFeedItems" class="mt-4 md:px-4 lg:mt-8 lg:px-8">
    <div class="mx-auto max-w-screen-2xl">
      <div class="px-4 text-lg space-y-4 md:px-0">
        <ul class="max-w-screen-sm space-y-2 lg:space-y-4">
          <li class="flex items-start">
            <BaseIcon class="mr-2 transform translate-y-0.5"><CheckIcon /></BaseIcon>
            <p class="flex-1">
              Looks like you're not following anyone yet,
              <RouterLink
                to="/members"
                class="
                  lg:hover:text-orange-500
                  lg:hover:border-orange-500
                  inline-block
                  leading-normal
                  border-b border-black
                  transition-colors
                  duration-150
                  ease-in-out
                "
              >
                find some friends</RouterLink
              >
              to follow to see what they've been up to.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        isLoading: false,
        isLoadingNextPage: false,
        hideViewMore: false,
        page: 1,
        perPage: 25,
        total: null,
        visible: null,
        feed: []
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        isLoading: 'getLoadingStatus',
        loggedInUser: 'auth/getLoggedInUser',
        isBottom: 'getIsBottom'
      }),
      hasFeedItems() {
        return !!this.feed && !!this.total
      }
    },
    watch: {
      async isBottom() {
        if (this.isBottom) await this.getFeed()
      }
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        this.isLoading = true
        this.$store.dispatch('setIsTrackingScrollPos', true)
        await this.getFeed()
        this.isLoading = false
      },
      async getFeed() {
        try {
          const feed = await this.apiRequest(
            `/feed`,
            null,
            {
              page: this.page,
              perPage: this.perPage
            },
            { Authorization: this.userJWT }
          )

          this.feed = this.feed.concat(feed.items)
          this.total = parseInt(feed.total)
          if (this.feed.length >= parseInt(this.total)) this.$store.dispatch('setIsTrackingScrollPos', false)
          this.page += 1
        } catch (e) {
          if (e) console.log(`Error getting feed: ${e}`)
        }

        this.$store.dispatch('setIsBottom', false)
      }
    }
  }
</script>
