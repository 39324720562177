<template>
  <AtomDashHeader :is-loading="isLoading" :title="title" :action-title="actionTitle" @action="buildForm()" />

  <!-- Videos -->
  <div v-if="!isLoading && hasVideos" class="mb-4 lg:mb-8">
    <OrganismVideos :videos="videos" centered @update-videos="updateVideoList" @delete-video="updateVideoList" />
  </div>

  <!-- Empty State -->
  <div v-if="!isLoading && !hasVideos" class="mt-4 md:px-4 lg:mt-8 lg:px-8">
    <div class="mx-auto max-w-screen-2xl">
      <div class="px-4 text-lg space-y-4 md:px-0">
        <ul class="max-w-screen-sm space-y-2 lg:space-y-4">
          <li class="flex items-start">
            <BaseIcon class="mr-2 transform translate-y-0.5"><CheckIcon /></BaseIcon>
            <p class="flex-1">
              Got some good footage whilst out on track? You can
              <button
                class="
                  lg:hover:text-orange-500
                  lg:hover:border-orange-500
                  inline-block
                  leading-normal
                  border-b border-black
                  focus:outline-none
                  focus:shadow-none
                  transition-colors
                  duration-150
                  ease-in-out
                "
                @click.prevent="buildForm()"
              >
                add a new YouTube video</button
              >.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        title: 'Videos',
        actionTitle: 'Add a video',
        isLoading: null,
        form: {
          cta: 'Add video',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'name',
              label: 'Name',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'description',
              label: 'Description',
              elem: 'textarea',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            },
            {
              id: 'event_uid',
              label: 'Event',
              elem: 'select',
              isRequired: null,
              classes: 'sm:col-span-2',
              children: [
                {
                  tag: 'option',
                  text: 'None',
                  value: 'none'
                }
              ],
              instruction: {
                content: "Can't see the event you've attended?",
                url: '/events',
                cta: 'You can add a past event.'
              }
            },
            {
              id: 'circuit_uid',
              label: 'Circuit',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'layout_uid',
              label: 'Layout',
              elem: 'select',
              isRequired: 'true',
              children: [],
              disabled: true
            },
            {
              id: 'date',
              label: 'Date',
              elem: 'input',
              type: 'date',
              min: '2000-01-01',
              max: `${new Date().getFullYear()}-12-31`,
              isRequired: 'true'
            },
            {
              id: 'youtube_id',
              label: 'YouTube Video URL',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'car_uid',
              label: 'Car',
              elem: 'select',
              isRequired: 'true',
              children: [],
              classes: 'sm:col-span-2',
              children: [
                {
                  tag: 'option',
                  text: 'None',
                  value: 'none'
                }
              ],
              instruction: {
                content: "Don't have any cars saved?",
                url: '/dashboard/cars',
                cta: 'Add a car.'
              }
            }
          ]
        },
        videos: null
      }
    },
    computed: {
      hasVideos() {
        return this.videos && this.videos.length > 0
      },
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        loggedInUser: 'auth/getLoggedInUser',
        circuits: 'circuits/getCircuits',
        today: 'getToday'
      })
    },
    watch: {
      async panelStatus(res) {
        if (res) await this.loadContent(true)
      },
      '$route.query.u'() {
        if (this.$route.query && this.$route.query.u) this.showVideo(this.$route.query.u)
      }
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent(async = false) {
        if (!async) this.isLoading = true
        await Promise.all([this.getUserVideos()])
        if (this.$route.query.u) this.showVideo(this.$route.query.u)
        if (this.$route.query.p) await this.buildForm()
        if (!async) this.isLoading = false
      },
      async getUserVideos() {
        try {
          this.videos = await this.apiRequest(`/user/${this.loggedInUser.id}/videos`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting videos: ${e.status} ${e.data}`)
        }
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        await Promise.all([this.$store.dispatch('circuits/getCircuits'), this.getEvents(), this.getUserCars()])

        this.setCircuits()
        this.setEvents()
        this.setCars()

        if (this.$route.query.p) {
          let queries = JSON.parse(JSON.stringify(this.$route.query))
          queries.p = undefined

          this.$router.replace({ query: queries })
        }

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          endpoint: '/video',
          errorMessage: "Sorry we couldn't add your video.",
          successMessage: 'Your video has been added.'
        })
      },
      async getEvents() {
        try {
          this.events = await this.apiRequest(
            `/user/${this.loggedInUser.id}/events`,
            null,
            { basic: true, start: '2000-01-01', end: this.today },
            {
              Authorization: this.userJWT
            }
          )
        } catch (e) {
          if (e) console.log('Error getEvents')
        }
      },
      async getUserCars() {
        try {
          this.cars = await this.apiRequest(`/user/${this.loggedInUser.id}/cars`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting cars: ${e.status} ${e.data}`)
        }
      },
      async setCars() {
        const carFormField = this.form.fields.find((field) => field.id === 'car_uid')

        this.cars.forEach((car) => {
          carFormField.children.push({
            tag: 'option',
            text: `${car.car_name} (${car.manufacturer} - ${car.model})`,
            value: car.uid
          })
        })
      },
      async setEvents() {
        const eventFormField = this.form.fields.find((field) => field.id === 'event_uid')
        const pastEvents = this.events.filter((event) => {
          return Date.parse(event.start_date) <= Date.now() ? event : null
        })
        const re = /(\d{4})[-. \/](\d\d)[-. \/](\d\d)/

        pastEvents.forEach((event) => {
          const date = event.start_date.slice(0, 10).replace(re, '$3/$2/$1')
          let text = `${date} - ${event.circuit} `

          if (event.slug.includes('evening')) text += `- Evening `
          text += `(${event.organiser})`

          eventFormField.children.push({
            tag: 'option',
            text,
            value: event.uid
          })
        })
      },
      async setCircuits() {
        const circuitFormField = this.form.fields.find((field) => field.id === 'circuit_uid')

        this.circuits.forEach((circuit) => {
          circuitFormField.children.push({
            tag: 'option',
            text: circuit.name,
            value: circuit.uid
          })
        })
      },
      async updateVideoList(videoUid) {
        this.videos = this.videos.filter((video) => {
          return video.uid !== videoUid
        })
      },
      async showVideo(videoUid) {
        const video = this.videos.find((video) => video.uid === videoUid)

        this.$store.dispatch('setOverlay', {
          youtube_id: video.youtube_id,
          uid: video.uid,
          video_uid: video.uid,
          url: video.uid,
          owner_uid: video.owner_uid,
          liked: video.liked ? video.liked : null,
          title: video.name ? video.name : `${video.circuit_name} - ${video.layout_name}`,
          content: video.description,
          meta: {
            username: video.username,
            date: video.event_date,
            url: video.event_slug,
            lap_time: video.lap_time,
            car: {
              name: video.car_name,
              description: video.car_description
            }
          }
        })
      }
    }
  }
</script>
