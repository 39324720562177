<template>
  <div>
    <div class="text-center">
      <h2 class="text-gray-900 text-3xl font-extrabold tracking-tight leading-9 sm:text-4xl sm:leading-10">
        {{ title }}
      </h2>
      <p class="text-gray-500 mt-4 text-lg leading-6">
        {{ content }}
      </p>
    </div>
    <div class="mt-12">
      <MoleculeForm
        :fields="form.fields"
        :cta="form.cta"
        :classes="form.classes"
        :is-saving="isSaving"
        @form-submitted="sendEmail"
      />
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        title: 'Contact us',
        content: 'Send us a message for questions about Tracklimit',
        form: {
          cta: 'Send message',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'first_name',
              label: 'First name',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'last_name',
              label: 'Last name',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'email',
              label: 'Email Address',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'message',
              label: 'Message',
              elem: 'textarea',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            }
          ]
        },
        isSaving: false
      }
    },
    methods: {
      async sendEmail(values) {
        this.isSaving = true

        try {
          // this.$store.dispatch('sendEmail', values)

          this.form.fields.forEach((field) => {
            console.log(field.value)
          })
        } catch (e) {
          if (e) console.log(`Error sending email: ${e.status} ${e.data}`)
        }

        this.isSaving = false
      }
    }
  }
</script>
