<template>
  <MoleculeText title="Privacy Policy">
    <template #default>
      <p>
        Tracklimit Ltd has created this privacy statement in order to demonstrate our firm commitment to privacy. The
        following discloses our information gathering and dissemination practices for this website. We do not store
        personal or financial information in the process of payment, nor do we share such information with 3rd parties.
      </p>
      <h2>What data is collected ?</h2>
      <ul>
        <li>
          When you register on our website we collect your name, address & contact number along with your email address
          and your password on your account.
        </li>
        <li>
          When you book via our website we also collect information on events you may be interested in as well as your
          vehicle details i.e. make/model.
        </li>
        <li>When you browse our website we also collect information on the IP address and browser type being used.</li>
      </ul>
      <h2>How is that data used ?</h2>
      <ul>
        <li>
          To manage the accounts you have registered with us. All passwords are encrypted on the website and staff do
          not have access to that information at all.
        </li>
        <li>
          We only use contact information to supply event details to participants or to respond to communication that
          has been directed at us via email or social media.
        </li>
        <li>
          We only ever use contact information for marketing purposes when someone has specifically signed up for our
          newsletter during account registration or afterwards via the website. We’ve never sent out unsolicited
          marketing emails and never will.
        </li>
        <li>When you browse our website we also collect information on the IP address and browser type being used.</li>
      </ul>
      <h2>How is that data stored ?</h2>
      <ul>
        <li>Account information is securely stored on our website.</li>
        <li>
          All payment information online is securely handled via our payment providers. Tracklimit Ltd does not host or
          store any payment information online. Where payment information is given via telephone, it is immediately
          shredded after processing.
        </li>
        <li>
          Information supplied to us via indemnity forms is securely stored for a period of 7 years as required by law,
          this data is only in physical form.
        </li>
        <li>
          If you wish to delete your data with us at any point then you can make that request via the Account Deletion
          Request Form
        </li>
        <li>Our website is regularly scanned to ensure that it is safe.</li>
      </ul>
      <h2>How is that data shared ?</h2>
      <ul>
        <li>We will never sell your information or share it with other parties for marketing purposes.</li>
        <li>We may share your personal information with our insurers in the event of a claim</li>
        <li>
          We may share your personal information with the circuits that we use but only for legal purposes i.e.
          recording of an accident.
        </li>
        <li>
          We would only ever share your personal information with another third party if we were legally compelled to
          do.
        </li>
      </ul>
    </template>
  </MoleculeText>
</template>
