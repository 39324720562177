<template>
  <div class="mx-auto w-full max-w-screen-2xl">
    <MoleculeTabHeader :title="title" :links="links" />
    <div class="pt-4 min-h-screen md:pt-6 lg:mb-0">
      <RouterView />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isBottom: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        title: 'Dashboard',
        links: [
          {
            title: 'Events',
            url: '/dashboard/events'
          },
          {
            title: 'Photographs',
            url: '/dashboard/photographs'
          },
          {
            title: 'Videos',
            url: '/dashboard/videos'
          },
          {
            title: 'Lap times',
            url: '/dashboard/lap-times'
          },
          {
            title: 'Leaderboards',
            url: '/dashboard/leaderboards'
          },
          {
            title: 'Cars',
            url: '/dashboard/cars'
          },
          {
            title: 'Friends',
            url: '/dashboard/friends'
          }
          // {
          //   title: 'Stats',
          //   url: '/dashboard/stats'
          // }
        ]
      }
    },
    mounted() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
</script>
