<template>
  <AtomDashHeader :is-loading="isLoading" :title="title" :action-title="actionTitle" @action="buildForm()" />

  <!-- Cars -->
  <div v-if="!isLoading && hasCars" class="mb-4 lg:mb-8">
    <OrganismCars :cars="cars" centered @update-cars="getUserCars" />
  </div>

  <!-- Empty State -->
  <div v-if="!isLoading && !hasCars" class="mt-4 md:px-4 lg:mt-8 lg:px-8">
    <div class="mx-auto max-w-screen-2xl">
      <div class="px-4 text-lg space-y-4 md:px-0">
        <ul class="max-w-screen-sm space-y-2 lg:space-y-4">
          <li class="flex items-start">
            <BaseIcon class="mr-2 transform translate-y-0.5"><CheckIcon /></BaseIcon>
            <p class="flex-1">
              Want to keep a record of all your track cars? You can
              <button
                class="
                  lg:hover:text-orange-500
                  lg:hover:border-orange-500
                  border-b border-black
                  focus:outline-none
                  focus:shadow-none
                  transition-colors
                  duration-150
                  ease-in-out
                "
                @click.prevent="buildForm()"
              >
                add a car</button
              >, past or present, then create albums, add videos and lap times for each one.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        title: 'Cars',
        actionTitle: 'Add a car',
        isLoading: null,
        form: {
          cta: 'Add car',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'name',
              label: 'Car Name',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'manufacturer',
              label: 'Manufacturer',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'model',
              label: 'Model',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'year',
              label: 'Year',
              elem: 'input',
              type: 'number',
              min: '1900',
              max: new Date().getFullYear(),
              isRequired: null
            },
            {
              id: 'drive',
              label: 'Drive',
              elem: 'select',
              isRequired: 'true',
              children: [
                {
                  tag: 'option',
                  text: 'AWD/4WD',
                  value: 'awd'
                },
                {
                  tag: 'option',
                  text: 'FWD',
                  value: 'fwd'
                },
                {
                  tag: 'option',
                  text: 'RWD',
                  value: 'rwd'
                }
              ]
            },
            {
              id: 'power',
              label: 'Power (bhp)',
              elem: 'input',
              type: 'number',
              isRequired: null
            },
            {
              id: 'weight',
              label: 'Weight (kg)',
              elem: 'input',
              type: 'number',
              isRequired: null
            },
            {
              id: 'colour',
              label: 'Colour',
              elem: 'input',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            },
            {
              id: 'start_date',
              label: 'Bought',
              elem: 'input',
              type: 'month',
              min: '2000-01',
              max: `${new Date().getFullYear()}-12`,
              isRequired: 'true'
            },
            {
              id: 'end_date',
              label: 'Sold',
              elem: 'input',
              type: 'month',
              min: '2000-01',
              max: `${new Date().getFullYear()}-12`,
              isRequired: null
            },
            {
              id: 'description',
              label: 'Description',
              elem: 'textarea',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            }
          ]
        },
        cars: null
      }
    },
    computed: {
      hasCars() {
        return this.cars && !!this.cars.length
      },
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        loggedInUser: 'auth/getLoggedInUser',
        today: 'getToday'
      })
    },
    watch: {
      async panelStatus(res) {
        if (res) await this.loadContent(true)
      }
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent(async = false) {
        if (!async) this.isLoading = true
        await Promise.all([this.getUserCars()])
        if (!async) this.isLoading = false
      },
      async getUserCars() {
        try {
          this.cars = await this.apiRequest(`/user/${this.loggedInUser.id}/cars`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting cars: ${e.status} ${e.data}`)
        }
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        this.setMaxDate()

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          endpoint: '/car',
          errorMessage: "Sorry we couldn't add your car.",
          successMessage: 'Your car has been added.'
        })
      },
      setMaxDate() {
        const start_date = this.form.fields.find((field) => field.id === 'start_date')
        const end_date = this.form.fields.find((field) => field.id === 'end_date')
        const year = this.form.fields.find((field) => field.id === 'year')
        const thisMonth = this.today.substring(0, this.today.length - 3)
        const thisYear = this.today.substring(0, this.today.length - 6)

        start_date.max = thisMonth
        end_date.max = thisMonth
        year.max = thisYear
      }
    }
  }
</script>
