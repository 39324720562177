<template>
  <AtomDashHeader :is-loading="isLoading" :title="title" :action-title="actionTitle" @action="buildForm()" />

  <!-- Lap times -->
  <div v-if="!isLoading && hasLapTimes" class="mb-4 lg:mb-8">
    <div class="mt-4 lg:mt-6">
      <div class="mx-auto max-w-screen-2xl">
        <MoleculeCircuitLayout
          v-for="(layouts, name, idx) in circuits"
          :key="name"
          :circuit-name="name"
          :layouts="layouts"
          :class="[
            'flex flex-wrap justify-between flex-col px-4 lg:px-8 pb-4 lg:pb-8 border-b border-gray',
            idx > 0 ? 'mt-8' : null
          ]"
          @update-laps="updateLapList"
        />
      </div>
    </div>
  </div>

  <!-- Empty State -->
  <div v-if="!isLoading && !hasLapTimes" class="mt-4 md:px-4 lg:mt-8 lg:px-8">
    <div class="mx-auto max-w-screen-2xl">
      <div class="px-4 text-lg space-y-4 md:px-0">
        <ul class="max-w-screen-sm space-y-2 lg:space-y-4">
          <li class="flex items-start">
            <BaseIcon class="mr-2 transform translate-y-0.5"><CheckIcon /></BaseIcon>
            <p class="flex-1">
              Want to keep a record of all the quick laps you've done? Go
              <button
                class="
                  lg:hover:text-orange-500
                  lg:hover:border-orange-500
                  inline-block
                  leading-normal
                  border-b border-black
                  focus:outline-none
                  focus:shadow-none
                  transition-colors
                  duration-150
                  ease-in-out
                "
                @click.prevent="buildForm()"
              >
                add a lap time</button
              >, video proof required!
            </p>
          </li>
          <li class="flex items-start">
            <BaseIcon class="mr-2 transform translate-y-0.5"><CheckIcon /></BaseIcon>
            <p class="flex-1">
              Go see how your times stack up against everyone else over on
              <RouterLink
                class="
                  lg:hover:text-orange-500
                  lg:hover:border-orange-500
                  inline-block
                  leading-normal
                  border-b border-black
                  focus:outline-none
                  focus:shadow-none
                  transition-colors
                  duration-150
                  ease-in-out
                "
                to="/lap-times"
                >lap times</RouterLink
              >.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  const groupBy = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {})

  export default {
    data() {
      return {
        title: 'Lap times',
        actionTitle: 'Add a lap time',
        isLoading: null,
        circuits: [],
        form: {
          cta: 'Add lap time',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'event_uid',
              label: 'Event',
              elem: 'select',
              isRequired: null,
              classes: 'sm:col-span-2',
              children: [
                {
                  tag: 'option',
                  text: 'None',
                  value: 'none'
                }
              ]
            },
            {
              id: 'circuit_uid',
              label: 'Circuit',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'layout_uid',
              label: 'Layout',
              elem: 'select',
              isRequired: 'true',
              children: [],
              disabled: true
            },
            {
              id: 'date',
              label: 'Date',
              elem: 'input',
              type: 'date',
              min: '2000-01-01',
              max: `${new Date().getFullYear()}-12-31`,
              isRequired: 'true'
            },
            {
              id: 'weather',
              label: 'Weather Conditions',
              elem: 'select',
              children: [
                {
                  tag: 'option',
                  text: 'Dry',
                  value: 'dry'
                },
                {
                  tag: 'option',
                  text: 'Wet',
                  value: 'wet'
                }
              ]
            },
            {
              id: 'laptime',
              label: 'Lap Time',
              placeholder: '00:00.000',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              instruction: {
                content: 'Format minutes:seconds.milliseconds'
              }
            },
            {
              id: 'youtube_id',
              label: 'YouTube Video URL',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'private',
              label: 'Do not display this lap time publicly',
              elem: 'input',
              type: 'checkbox',
              classes: 'sm:col-span-2 flex justify-end flex-row-reverse'
            }
          ]
        },
        jwt: null,
        events: null,
        laptimes: null
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        loggedInUser: 'auth/getLoggedInUser',
        allCircuits: 'circuits/getCircuits',
        today: 'getToday'
      }),
      hasLapTimes() {
        return !this.isLoading && this.laptimes && !!this.laptimes.length
      }
    },
    watch: {
      async panelStatus(res) {
        if (res) {
          this.getUserLapTimes()
        }
      }
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        this.isLoading = true
        await Promise.all([this.getUserLapTimes()])
        if (this.$route.query.p) await this.buildForm()
        this.isLoading = false
      },
      async getUserLapTimes() {
        try {
          this.laptimes = await this.apiRequest(`/user/${this.loggedInUser.id}/laptimes`, null, null, {
            Authorization: this.userJWT
          })

          this.circuits = groupBy(this.laptimes, (c) => c.circuit_name)

          Object.keys(this.circuits).forEach((circuit) => {
            this.circuits[circuit] = groupBy(this.circuits[circuit], (c) => c.layout_name)
          })
        } catch (e) {
          if (e) console.log(`Error getting Lap times: ${e.status} ${e.data}`)
        }
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        await Promise.all([this.$store.dispatch('circuits/getCircuits'), this.getEvents()])

        this.setCircuits()
        this.setEvents()
        this.setMaxDate()

        if (this.$route.query.p) {
          let queries = JSON.parse(JSON.stringify(this.$route.query))
          queries.p = undefined

          this.$router.replace({ query: queries })
        }

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          endpoint: '/laptime',
          errorMessage: "Sorry we couldn't add your lap time.",
          successMessage: 'Your lap time has been added.'
        })
      },
      async getEvents() {
        try {
          this.events = await this.apiRequest(
            `/user/${this.loggedInUser.id}/events`,
            null,
            { basic: true, start: '2000-01-01', end: this.today },
            {
              Authorization: this.userJWT
            }
          )
        } catch (e) {
          if (e) console.log('Error getEvents')
        }
      },
      async setCircuits() {
        const circuitFormField = this.form.fields.find((field) => field.id === 'circuit_uid')

        this.allCircuits.forEach((circuit) => {
          circuitFormField.children.push({
            tag: 'option',
            text: circuit.name,
            value: circuit.uid
          })
        })
      },
      async setLayouts() {
        const layoutFormField = this.form.fields.find((field) => field.id === 'layout_uid')

        this.layouts.forEach((layout) => {
          layoutFormField.children.push({
            tag: 'option',
            text: layout.name,
            value: layout.uid
          })
        })
      },
      async setEvents() {
        const eventFormField = this.form.fields.find((field) => field.id === 'event_uid')
        const pastEvents = this.events.filter((event) => {
          return Date.parse(event.start_date) <= Date.now() ? event : null
        })
        const re = /(\d{4})[-. \/](\d\d)[-. \/](\d\d)/

        pastEvents.forEach((event) => {
          const date = event.start_date.slice(0, 10).replace(re, '$3/$2/$1')
          let text = `${date} - ${event.circuit} `

          if (event.slug.includes('evening')) text += `- Evening `
          text += `(${event.organiser})`

          eventFormField.children.push({
            tag: 'option',
            text,
            value: event.uid
          })
        })
      },
      async setMaxDate() {
        const date = this.form.fields.find((field) => field.id === 'date')

        date.max = this.today
      },
      async updateLapList(laptimeUid) {
        this.laptimes = this.laptimes.filter((laptime) => {
          return laptime.uid !== laptimeUid
        })

        this.circuits = groupBy(this.laptimes, (c) => c.circuit_name)

        Object.keys(this.circuits).forEach((circuit) => {
          this.circuits[circuit] = groupBy(this.circuits[circuit], (c) => c.layout_name)
        })
      }
    }
  }
</script>
