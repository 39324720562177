<template>
  <AtomDashHeader :is-loading="isLoading" :title="title" :action-title="actionTitle" @action="goToMembers()" />
  <template v-if="!isLoading">
    <!-- Following -->
    <div v-if="hasFollowing" class="mt-4 pb-4 border-b border-gray md:px-4 lg:mt-6 lg:pb-8 lg:px-8">
      <div class="mx-auto max-w-screen-2xl">
        <h3 class="px-4 text-lg md:px-0">Following</h3>
        <div
          class="
            grid
            gap-4
            2xl:grid-cols-4
            mt-2
            px-4
            sm:grid-cols-2
            md:grid-cols-1
            md:px-0
            lg:grid-cols-2
            xl:grid-cols-3
          "
        >
          <MoleculeMember v-for="member in following" :key="member.uid" :user="member" @updated="loadContent(true)" />
        </div>
      </div>
    </div>

    <!-- Followers -->
    <div v-if="hasFollowers" class="mb-4 mt-4 md:px-4 lg:mb-8 lg:mt-6 lg:px-8">
      <div class="mx-auto max-w-screen-2xl">
        <h3 class="px-4 text-lg md:px-0">Followers</h3>
        <div
          class="
            grid
            gap-4
            2xl:grid-cols-4
            mt-2
            px-4
            sm:grid-cols-2
            md:grid-cols-1
            md:px-0
            lg:grid-cols-2
            xl:grid-cols-3
          "
        >
          <MoleculeMember v-for="member in followers" :key="member.uid" :user="member" @updated="loadContent(true)" />
        </div>
      </div>
    </div>

    <!-- Empty State -->
    <div v-if="!isLoading && !hasFollowing" class="mt-4 md:px-4 lg:mt-8 lg:px-8">
      <div class="mx-auto max-w-screen-2xl">
        <div class="px-4 text-lg space-y-4 md:px-0">
          <ul class="max-w-screen-sm space-y-2 lg:space-y-4">
            <li class="flex items-start">
              <BaseIcon class="mr-2 transform translate-y-0.5"><CheckIcon /></BaseIcon>
              <p class="flex-1">
                It's looking a bit lonely over here, go
                <RouterLink
                  class="
                    lg:hover:text-orange-500
                    lg:hover:border-orange-500
                    border-b border-black
                    focus:outline-none
                    focus:shadow-none
                    transition-colors
                    duration-150
                    ease-in-out
                  "
                  to="/members"
                >
                  find a friend
                </RouterLink>
                so you can see
                <RouterLink
                  class="
                    lg:hover:text-orange-500
                    lg:hover:border-orange-500
                    border-b border-black
                    focus:outline-none
                    focus:shadow-none
                    transition-colors
                    duration-150
                    ease-in-out
                  "
                  to="/events&friends_attending=true#Filters"
                  >what track days they've got coming up</RouterLink
                >.
              </p>
            </li>
            <li class="flex items-start">
              <BaseIcon class="mr-2 transform translate-y-0.5"><CheckIcon /></BaseIcon>
              <p class="flex-1">
                You can even keep tabs on their latest photographs, videos and lap times over on
                <RouterLink
                  class="
                    lg:hover:text-orange-500
                    lg:hover:border-orange-500
                    border-b border-black
                    focus:outline-none
                    focus:shadow-none
                    transition-colors
                    duration-150
                    ease-in-out
                  "
                  to="/"
                  >Race control</RouterLink
                >.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        title: 'Friends',
        actionTitle: 'Find friends',
        following: null,
        followers: null,
        jwt: null,
        isLoading: null
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        loggedInUser: 'auth/getLoggedInUser'
      }),
      hasFollowers() {
        return this.followers && this.followers.length > 0
      },
      hasFollowing() {
        return this.following && this.following.length > 0
      }
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent(async = false) {
        if (!async) this.isLoading = true
        await Promise.all([this.getUserFollowing(), this.getUserFollowers()])
        if (!async) this.isLoading = false
      },
      async getUserFollowing() {
        try {
          this.following = await this.apiRequest(`/user/${this.loggedInUser.id}/following`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting users you are following: ${e.status} ${e.data}`)
        }
      },
      async getUserFollowers() {
        try {
          this.followers = await this.apiRequest(`/user/${this.loggedInUser.id}/followers`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting user followers: ${e.status} ${e.data}`)
        }
      },
      goToMembers() {
        this.$router.push({
          path: '/members'
        })
      }
    }
  }
</script>
