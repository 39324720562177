<template v-if="!isLoading">
  <div>
    <AtomSectionHeader
      classes="md:px-4 lg:px-8"
      :title="title"
      icon="PlusIcon"
      :action-title="actionTitle"
      @action="buildForm"
    />
    <div class="mb-4 lg:mb-8">
      <OrganismEvents :events="events" :filters="filters" :is-loading="isLoadingEvents" @update-content="getEvents" />
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    inject: ['sidebar'],
    data() {
      return {
        title: 'Events',
        form: {
          cta: 'Add event',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'circuit_uid',
              label: 'Circuit',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'layout_uid',
              label: 'Layout',
              elem: 'select',
              isRequired: 'true',
              children: [],
              disabled: true
            },
            {
              id: 'organiser_uid',
              label: 'Organiser',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'format_uid',
              label: 'Format',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'start_date',
              label: 'Date',
              elem: 'input',
              type: 'date',
              min: '2000-01-01',
              max: '2031-05-21',
              isRequired: 'true'
            },
            {
              id: 'url',
              label: 'Event URL',
              elem: 'input',
              type: 'text',
              isRequired: null
            },
            {
              id: 'event_evening',
              label: 'Evening event?',
              elem: 'input',
              type: 'checkbox',
              isRequired: null,
              classes: 'sm:col-span-2 flex justify-end flex-row-reverse'
            }
          ]
        },
        filters: {
          fields: [
            {
              id: 'circuit',
              label: 'Circuit',
              elem: 'select',
              children: [],
              classes: 'flex-auto w-full mt-4 lg:mr-4 lg:mt-0 lg:max-w-2xl'
            },
            {
              id: 'organiser',
              label: 'Organiser',
              elem: 'select',
              children: [],
              classes: 'flex-auto w-full mt-4 lg:mr-4 lg:mt-0 lg:max-w-2xl'
            },
            {
              id: 'start_date',
              label: 'Date',
              elem: 'select',
              children: [],
              classes: 'flex-auto w-full mt-4 lg:mr-4 lg:mt-0 lg:max-w-2xl'
            },
            {
              id: 'format',
              label: 'Format',
              elem: 'select',
              children: [],
              classes: 'flex-auto w-full mt-4 lg:mr-4 lg:mt-0 lg:max-w-2xl'
            },
            {
              field: 'friends_attending',
              id: 'friends_attending',
              label: 'Friends only',
              elem: 'checkbox',
              classes: 'flex-auto w-full mt-4 lg:mt-0'
            }
          ]
        },
        events: [],
        page: 1,
        perPage: 20,
        initialLoad: true,
        selectedFilters: null,
        isLoadingNextPage: null,
        isLoadingEvents: true
      }
    },
    computed: {
      actionTitle() {
        return this.loggedInUser && this.loggedInUser.admin ? 'Add an event' : 'Add a past event'
      },
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        isLoading: 'getLoadingStatus',
        loggedInUser: 'auth/getLoggedInUser',
        circuits: 'circuits/getCircuits',
        organisers: 'organisers/getOrganisers',
        formats: 'formats/getFormats',
        today: 'getToday',
        isBottom: 'getIsBottom'
      })
    },
    watch: {
      '$route.params': function () {
        if (Object.keys(this.$route.query).length === 0) this.getEvents()
      },
      async panelStatus(res) {
        if (res) await this.loadContent(true)
      },
      async isBottom() {
        if (this.isBottom) await this.getNextPage()
      }
    },
    mounted() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent(refresh) {
        this.$store.dispatch('setIsTrackingScrollPos', true)

        this.$store.dispatch('setLoadingStatus', true)

        if (refresh) {
          await this.getEvents(this.selectedFilters)
        }

        if (this.$route.query.p) await this.buildForm()
      },
      async getEvents(filters) {
        this.isLoadingEvents = true

        await Promise.all([
          this.$store.dispatch('circuits/getCircuits'),
          this.$store.dispatch('organisers/getOrganisers'),
          this.$store.dispatch('formats/getFormats')
        ])

        this.selectedFilters = filters

        if (this.initialLoad) this.setFilters()

        try {
          const circuits =
            filters && filters.circuit
              ? [this.circuits.find((circuit) => circuit.name === filters.circuit).name]
              : this.circuits && this.circuits.length
              ? this.circuits.map((circuit) => circuit.name)
              : null
          const organisers =
            filters && filters.organiser
              ? [this.organisers.find((organiser) => organiser.name === filters.organiser).name]
              : this.organisers && this.organisers.length
              ? this.organisers.map((organiser) => organiser.name)
              : null
          const formats =
            filters && filters.format
              ? [this.formats.find((format) => format.name === filters.format).name]
              : this.formats && this.formats.length
              ? this.formats.map((format) => format.name)
              : null
          const endOfMonth = new Date(this.today.split('-')[0], this.today.split('-')[1], 0)
            .toISOString()
            .slice(0, 10)
            .replace('T', ' ')
          const startDate = filters && filters.start_date ? filters.start_date : this.today
          const endDate = startDate
            ? startDate.length === 10
              ? startDate === this.today
                ? new Date(parseInt(startDate.split('-')[0]) + 1, startDate.split('-')[1], 0)
                    .toISOString()
                    .slice(0, 10)
                    .replace('T', ' ')
                : new Date(startDate.split('-')[0], startDate.split('-')[1], 0)
                    .toISOString()
                    .slice(0, 10)
                    .replace('T', ' ')
              : `${startDate}-12-31`
            : endOfMonth
          const events = await this.apiRequest(
            `/event/events`,
            null,
            {
              start: startDate.length === 10 ? startDate : `${startDate}-01-01`,
              end: endDate,
              circuits: JSON.stringify(circuits),
              organisers: JSON.stringify(organisers),
              formats: JSON.stringify(formats),
              perPage: this.perPage,
              friends_attending: filters && filters.friends_attending
            },
            { Authorization: this.userJWT }
          )

          this.events =
            filters && filters.friends_attending
              ? events.events.filter((event) => event.friends_attending)
              : events.events
          this.page = 2
          if (events.total > this.events.length) this.$store.dispatch('setIsTrackingScrollPos', true)
        } catch (e) {
          if (e) console.log(`Error getting events: ${e}`)
        }

        this.isLoadingEvents = false
        this.$store.dispatch('setLoadingStatus', false)
        this.initialLoad = false
      },
      async getNextPage() {
        const filters = this.selectedFilters
        const cta = event.target

        if (cta && cta.style) cta.style.width = `${cta.offsetWidth}px`
        this.isLoadingNextPage = true

        try {
          const circuits =
            filters && filters.circuit
              ? [this.circuits.find((circuit) => circuit.name === filters.circuit).name]
              : this.circuits && this.circuits.length
              ? this.circuits.map((circuit) => circuit.name)
              : null
          const organisers =
            filters && filters.organiser
              ? [this.organisers.find((organiser) => organiser.name === filters.organiser).name]
              : this.organisers && this.organisers.length
              ? this.organisers.map((organiser) => organiser.name)
              : null
          const formats =
            filters && filters.format
              ? [this.formats.find((format) => format.name === filters.format).name]
              : this.formats && this.formats.length
              ? this.formats.map((format) => format.name)
              : null
          const endOfMonth = new Date(this.today.split('-')[0], this.today.split('-')[1], 0)
            .toISOString()
            .slice(0, 10)
            .replace('T', ' ')
          const startDate = filters && filters.start_date ? filters.start_date : this.today
          const endDate = startDate
            ? startDate.length === 10
              ? startDate === this.today
                ? new Date(parseInt(startDate.split('-')[0]) + 1, startDate.split('-')[1], 0)
                    .toISOString()
                    .slice(0, 10)
                    .replace('T', ' ')
                : new Date(startDate.split('-')[0], startDate.split('-')[1], 0)
                    .toISOString()
                    .slice(0, 10)
                    .replace('T', ' ')
              : `${startDate}-12-31`
            : endOfMonth
          const events = await this.apiRequest(
            `/event/events`,
            null,
            {
              start: startDate.length === 10 ? startDate : `${startDate}-01-01`,
              end: endDate,
              circuits: JSON.stringify(circuits),
              organisers: JSON.stringify(organisers),
              formats: JSON.stringify(formats),
              perPage: this.perPage,
              page: this.page,
              friends_attending: filters && filters.friends_attending
            },
            { Authorization: this.userJWT }
          )

          this.events = this.events.concat(events.events)
          this.page += 1
          if (this.events.length >= events.total) this.$store.dispatch('setIsTrackingScrollPos', false)
        } catch (e) {
          if (e) console.log(`Error getting events: ${e}`)
        }

        if (cta && cta.style) cta.style.width = null
        this.isLoadingNextPage = false
        this.$store.dispatch('setIsBottom', false)
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        this.setMaxDate()
        this.setCircuits()
        this.setOrganisers()
        this.setFormats()

        if (this.$route.query.p) {
          let queries = JSON.parse(JSON.stringify(this.$route.query))
          queries.p = undefined

          this.$router.replace({ query: queries })
        }

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          endpoint: '/event',
          ctaTitle: 'View event',
          errorMessage: "Sorry we couldn't add the event."
        })
      },
      setFilters() {
        const circuitFilterField = this.filters.fields.find((filter) => filter.id === 'circuit')
        const organiserFilterField = this.filters.fields.find((filter) => filter.id === 'organiser')
        const formatFilterField = this.filters.fields.find((filter) => filter.id === 'format')
        const dateFilterField = this.filters.fields.find((filter) => filter.id === 'start_date')
        const years = [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023]
        const months = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ]

        this.circuits.forEach((circuit) => {
          circuitFilterField.children.push({
            text: circuit.name,
            value: circuit.name
          })
        })

        this.organisers.forEach((organiser) => {
          organiserFilterField.children.push({
            text: organiser.name,
            value: organiser.name
          })
        })

        this.formats.forEach((format) => {
          formatFilterField.children.push({
            text: format.name,
            value: format.name
          })
        })

        years.forEach((year) => {
          dateFilterField.children.unshift({
            year: year
          })
        })

        dateFilterField.children.unshift({
          value: {
            year: 2024,
            months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
          }
        })
      },
      setCircuits() {
        const circuitFormField = this.form.fields.find((field) => field.id === 'circuit_uid')

        this.circuits.forEach((circuit) => {
          circuitFormField.children.push({
            tag: 'option',
            text: circuit.name,
            value: circuit.uid
          })
        })
      },
      setOrganisers() {
        const organiserFormField = this.form.fields.find((field) => field.id === 'organiser_uid')

        this.organisers.forEach((organiser) => {
          organiserFormField.children.push({
            tag: 'option',
            text: organiser.name,
            value: organiser.uid
          })
        })
      },
      setFormats() {
        const formatFormField = this.form.fields.find((field) => field.id === 'format_uid')

        this.formats.forEach((format) => {
          formatFormField.children.push({
            tag: 'option',
            text: format.name,
            value: format.uid
          })
        })
      },
      setMaxDate() {
        const start_date = this.form.fields.find((field) => field.id === 'start_date')

        start_date.max = this.loggedInUser.admin ? '2030-12-31' : this.today
      }
    }
  }
</script>
