<template v-if="!isLoading">
  <div :class="[!loggedInUser ? ' md:mt-20' : null]">
    <MoleculeCover :cover-photo-url="coverPhotoUrl" @show-panel="buildForm()" />

    <AtomPageTitle
      v-if="!isLoading && isEvent"
      :title="event.circuit"
      :sub-title="event.layout"
      :class="[!loggedInUser && !hasAlbums && !hasVideos && !hasLaptimes && !comments ? '' : 'border-b border-gray']"
    >
      <template #aside>
        <ul class="text-md col-span-4 space-y-2 lg:text-lg">
          <!-- <li>Cloudy <span class="font-bold">12&#176;/7&#176;</span></li> -->
          <li>
            <div :class="['flex', isEveningEvent ? 'items-top' : 'items-center']">
              <BaseIcon class="mr-2 w-7 h-7"><EventIcon /></BaseIcon>
              <AtomFormattedDate :date="event.start_date"></AtomFormattedDate>
              <template v-if="isEveningEvent"> (Evening)</template>
            </div>
          </li>
          <li v-if="event.circuit">
            <RouterLink
              :to="`/circuit/${event.circuit_slug}`"
              class="justify-left lg:hover:text-orange-500 flex items-center transition-colors duration-150 ease-in-out"
              :title="`View more events at ${event.circuit}`"
            >
              <BaseIcon class="mr-2 w-7 h-7">
                <CircuitIcon />
              </BaseIcon>
              {{ event.circuit }}
            </RouterLink>
          </li>
          <li v-if="event.organiser">
            <RouterLink
              :to="`/organiser/${event.organiser_slug}`"
              class="justify-left lg:hover:text-orange-500 flex items-center transition-colors duration-150 ease-in-out"
              :title="`View more events by ${event.organiser}`"
            >
              <BaseIcon class="mr-2 w-7 h-7">
                <OrganiserIcon />
              </BaseIcon>
              {{ event.organiser }}
            </RouterLink>
          </li>
          <li v-if="tdoUrl">
            <a
              :href="tdoUrl"
              target="_blank"
              class="justify-left lg:hover:text-orange-500 flex items-center transition-colors duration-150 ease-in-out"
              :title="`View event details on ${event.organiser} website`"
            >
              <BaseIcon class="mr-2 w-7 h-7">
                <ExternalIcon />
              </BaseIcon>
              TDO Website
            </a>
          </li>
        </ul>
      </template>

      <template #actions>
        <ul class="grid gap-4 grid-cols-4 mt-6 w-full lg:grid-cols-12 lg:mt-8">
          <li v-if="loggedInUser" class="col-span-4 sm:col-span-2 lg:col-span-4 xl:col-span-3">
            <button
              :class="[
                'w-full flex items-center justify-center px-5 py-3 border border-transparent text-base focus:outline-none leading-6 font-medium rounded-sm transition duration-150 ease-in-out',
                !isAttending
                  ? 'bg-gray text-black lg:hover:bg-orange-500 lg:hover:text-white'
                  : 'text-white bg-orange-500 lg:hover:bg-orange-300'
              ]"
              @click.prevent="updateAttendingStatus"
            >
              <span v-if="!isUpdatingStatus" class="pointer-events-none">
                <span v-if="!isAttending">{{ attending }} </span>
                <span v-else>{{ attended }}</span>
              </span>
              <AtomLoader v-else></AtomLoader>
            </button>
          </li>
          <li v-else class="col-span-4 sm:col-span-2 lg:col-span-4 xl:col-span-3">
            <AtomCTA type="RouterLink" to="/join" title="Join to attend" large />
          </li>
          <!-- <li class="col-span-4 sm:col-span-2 lg:col-span-4 xl:col-span-3">
            <AtomCTA url="#" popup-title="Copy URL to clipboard" title="Share" ghost large />
          </li> -->
        </ul>
      </template>
    </AtomPageTitle>

    <div class="flex flex-col gap-4 mb-4 divide-gray divide-y lg:gap-8 lg:mb-8">
      <section v-if="hasMembers">
        <AtomSectionHeader classes="md:px-4 lg:px-8" title="Attendees" heading-tag="h2" />
        <OrganismMembers :members="members" centered @update-member="updateMemberFollowing" />
      </section>

      <section v-if="isPastEvent">
        <AtomSectionHeader
          v-if="isAttending"
          classes="md:px-4 lg:px-8"
          title="Albums"
          heading-tag="h2"
          icon="PlusIcon"
          action-title="Create event album"
          @action="buildForm('album')"
        />
        <AtomSectionHeader v-else classes="md:px-4 lg:px-8" title="Albums" heading-tag="h2" />
        <OrganismAlbums v-if="hasAlbums" :on-event="true" :albums="albums" centered />
      </section>

      <section v-if="isPastEvent">
        <AtomSectionHeader
          v-if="isAttending"
          classes="md:px-4 lg:px-8"
          title="Videos"
          heading-tag="h2"
          icon="PlusIcon"
          action-title="Add event video"
          @action="buildForm('video')"
        />
        <AtomSectionHeader v-else classes="md:px-4 lg:px-8" title="Videos" heading-tag="h2" />
        <OrganismVideos v-if="hasVideos" :videos="videos" centered :is-event="true" @update-videos="updateVideoList" />
      </section>

      <!-- <section v-if="isPastEvent">
        <AtomSectionHeader
          v-if="isAttending"
          classes="md:px-4 lg:px-8"
          title="Lap times"
          heading-tag="h2"
          icon="PlusIcon"
          action-title="Add event lap time"
          @action="buildForm('lap-time')"
        />
        <AtomSectionHeader v-else classes="md:px-4 lg:px-8" title="Lap times" heading-tag="h2" />
        <div v-if="hasLaptimes" class="mt-4 px-4 lg:px-8">
          <MoleculeLapTimes
            :circuit-name="event.circuit"
            :layout-name="event.layout"
            :circuit-uid="event.circuit_uid"
            :layout-uid="event.layout_uid"
            :event-uid="event.uid"
            :laptimes="laptimes"
            :is-editable="false"
            @update-laps="updateLapList"
          />
        </div>
      </section>-->

      <section v-if="event">
        <AtomSectionHeader classes="md:px-4 lg:px-8" title="Comments" heading-tag="h2" />
        <OrganismComments
          :comments="comments"
          :event-uid="event.uid"
          :content-uid="event.uid"
          @update-comments="updateCommentList"
        />
      </section>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    inject: ['sidebar'],
    props: {},
    data() {
      return {
        isSaving: false,
        isAttending: false,
        isUpdatingStatus: false,
        isImageLoaded: false,
        members: null,
        albums: null,
        comments: null,
        layouts: null,
        videos: null,
        laptimes: null,
        event: null,
        jwt: null,
        actionTitle: 'Update event'
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        isLoading: 'getLoadingStatus',
        loggedInUser: 'auth/getLoggedInUser',
        circuits: 'circuits/getCircuits',
        organisers: 'organisers/getOrganisers',
        formats: 'formats/getFormats',
        today: 'getToday'
      }),
      isEvent() {
        return !!this.event
      },
      attended() {
        const eventDate = Date.parse(this.event.start_date)

        if (eventDate < Date.now()) {
          return 'You attended'
        } else {
          return "You're attending"
        }
      },
      tdoUrl() {
        const eventDate = Date.parse(this.event.start_date)

        if (eventDate < Date.now()) {
          return false
        } else {
          return this.event.url
        }
      },
      attending() {
        const eventDate = Date.parse(this.event.start_date)

        if (eventDate < Date.now()) {
          return 'Attended?'
        } else {
          return 'Going?'
        }
      },
      hasMembers() {
        return !!this.members && !!this.members.length
      },
      hasAlbums() {
        return !!this.albums && !!this.albums.length
      },
      hasLaptimes() {
        return !!this.laptimes && !!this.laptimes.length
      },
      hasVideos() {
        return !!this.videos && !!this.videos.length
      },
      updateEventForm() {
        const startDate = this.event.start_date.split('-')
        const formattedStartDate = `${startDate[0]}-${startDate[1]}-${startDate[2].slice(0, 2)}`

        return {
          cta: 'Update event',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          initial: {
            start_date: formattedStartDate,
            url: this.event.url,
            slug: this.event.slug
          },
          fields: [
            {
              id: 'circuit_uid',
              label: 'Circuit',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'layout_uid',
              label: 'Layout',
              elem: 'select',
              isRequired: null,
              children: []
            },
            {
              id: 'organiser_uid',
              label: 'Organiser',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'format_uid',
              label: 'Format',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'start_date',
              label: 'Date',
              elem: 'input',
              type: 'date',
              min: '2000-01-01',
              max: '2031-05-21',
              isRequired: 'true'
            },
            {
              id: 'url',
              label: 'Event URL',
              elem: 'input',
              type: 'text',
              isRequired: null
            },
            {
              id: 'slug',
              label: 'Slug',
              elem: 'input',
              type: 'hidden',
              classes: 'hidden',
              isRequired: 'true'
            }
          ]
        }
      },
      addEventAlbumForm() {
        return {
          cta: 'Create album',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          initial: {
            event_uid: this.event.uid,
            date: this.event.start_date.substring(0, 10),
            eventAlbum: false
          },
          fields: [
            {
              id: 'name',
              label: 'Album name',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'event_uid',
              label: 'Event uid',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'hidden'
            },
            {
              id: 'date',
              label: 'Date taken',
              elem: 'input',
              type: 'date',
              isRequired: 'true',
              classes: 'hidden'
            }
          ]
        }
      },
      addEventVideoForm() {
        return {
          cta: 'Add video',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          initial: {
            event_uid: this.event.uid,
            circuit_uid: this.event.circuit_uid,
            layout_uid: this.event.layout_uid,
            date: this.event.start_date.substring(0, 10)
          },
          fields: [
            {
              id: 'event_uid',
              label: 'Event uid',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'hidden'
            },
            {
              id: 'circuit_uid',
              label: 'Circuit uid',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'hidden'
            },
            {
              id: 'layout_uid',
              label: 'Layout uid',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'hidden'
            },
            {
              id: 'date',
              label: 'Date taken',
              elem: 'input',
              type: 'date',
              isRequired: 'true',
              classes: 'hidden'
            },
            {
              id: 'name',
              label: 'Name',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'description',
              label: 'Description',
              elem: 'textarea',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            },
            {
              id: 'youtube_id',
              label: 'YouTube Video URL',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            }
          ]
        }
      },
      addEventLaptimeForm() {
        return {
          cta: 'Add lap time',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          initial: {
            event_uid: this.event.uid,
            circuit_uid: this.event.circuit_uid,
            layout_uid: this.event.layout_uid,
            date: this.event.start_date.substring(0, 10)
          },
          fields: [
            {
              id: 'event_uid',
              label: 'Event uid',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'hidden'
            },
            {
              id: 'circuit_uid',
              label: 'Circuit uid',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'hidden'
            },
            {
              id: 'layout_uid',
              label: 'Layout uid',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'hidden'
            },
            {
              id: 'date',
              label: 'Date taken',
              elem: 'input',
              type: 'date',
              isRequired: 'true',
              classes: 'hidden'
            },
            {
              id: 'laptime',
              label: 'Lap Time',
              placeholder: '00:00.000',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              instruction: {
                content: 'Format minutes:seconds.milliseconds'
              }
            },
            {
              id: 'youtube_id',
              label: 'YouTube Video URL',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            }
          ]
        }
      },
      addAlbumLink() {
        return `/photographs?event=${this.event.uid}`
      },
      coverPhotoUrl() {
        return this.event && this.event.hero ? `${process.env.VUE_APP_IMGIX}/${this.event.hero.path}` : null
      },
      isEveningEvent() {
        return this.event.slug.includes('evening')
      },
      isPastEvent() {
        return this.event && Date.parse(this.today) >= Date.parse(this.event.start_date)
      }
    },
    watch: {
      async panelStatus(res) {
        if (res && !!res.slug && res.slug !== this.$route.params.slug) {
          this.$router.push({ path: `/event/${res.slug}` })
        }
        await this.loadContent(res.slug)
      },
      '$route.query.u'() {
        if (this.$route.query && this.$route.query.u) this.showVideo(this.$route.query.u)
      },
      '$route.params.slug'() {
        this.loadContent()
      }
    },
    mounted() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent(slug) {
        this.$store.dispatch('setLoadingStatus', true)
        await this.getEventBySlug(slug)
        await Promise.all([
          this.getEventHero(),
          this.getEventMembers(),
          this.getEventAlbums(),
          this.getEventVideos(),
          // this.getEventLapTimes(),
          this.getComments()
        ])
        if (this.$route.query.u) this.showVideo(this.$route.query.u)
        this.$store.dispatch('setLoadingStatus', false)
      },
      async getEventBySlug(slug = this.$route.params.slug) {
        try {
          this.event = await this.apiRequest(`/event/${slug}`)
        } catch (e) {
          // this.$router.push({ name: 'NotFound' })
          if (e) console.log(`Error getting event: ${e.status} ${e.data}`)
        }
      },
      async getEventHero() {
        try {
          this.event.hero = await this.apiRequest(`/event/${this.event.uid}/photographs/hero`)

          if (this.event.hero) return

          this.event.hero = await this.apiRequest(`/circuit/${this.event.circuit_uid}/photographs/popular`)
        } catch (e) {
          if (e) console.log(`Error getting hero: ${e.status} ${e.data}`)
        }
      },
      async getEventMembers() {
        const headers = {}

        if (this.userJWT) headers.Authorization = this.userJWT

        try {
          this.members = await this.apiRequest(`/event/${this.event.uid}/users`, null, null, headers)
          if (this.members) this.checkAttendingStatus()
        } catch (e) {
          if (e) console.log(`Error getting event: ${e.status} ${e.data}`)
        }
      },
      async getEventAlbums() {
        try {
          this.albums = await this.apiRequest(`/event/${this.event.uid}/albums`)
        } catch (e) {
          if (e) console.log(`Error getting albums: ${e.status} ${e.data}`)
        }
      },
      async getEventLapTimes() {
        const headers = {}

        if (this.userJWT) headers.Authorization = this.userJWT

        try {
          this.laptimes = await this.apiRequest(`/event/${this.event.uid}/laptimes`, null, null, headers)
          this.laptimes.sort((a, b) => a.laptime - b.laptime)
        } catch (e) {
          if (e) console.log(`Error getting laptimes: ${e.status} ${e.data}`)
        }
      },
      async getEventVideos() {
        const headers = {}

        if (this.userJWT) headers.Authorization = this.userJWT

        try {
          this.videos = await this.apiRequest(`/event/${this.event.uid}/videos`, null, null, headers)
        } catch (e) {
          if (e) console.log(`Error getting videos: ${e.status} ${e.data}`)
        }
      },
      async getComments() {
        const headers = {}

        if (this.userJWT) headers.Authorization = this.userJWT

        try {
          this.comments = await this.apiRequest('/comment/comments', null, { u: this.event.uid }, headers)
        } catch (e) {
          if (e) console.log(`Error getting comments: ${e.status} ${e.data}`)
        }
      },
      async buildForm(type) {
        switch (type) {
          case 'album':
            if (this.loggedInUser.admin) {
              this.addEventAlbumForm.fields.push({
                id: 'flickrUrl',
                label: 'Flickr Album URL',
                elem: 'input',
                type: 'text',
                isRequired: null,
                classes: 'sm:col-span-2',
                instruction: {
                  content: 'If you would like to automatically import photographs from a Flickr album.'
                }
              })
              this.addEventAlbumForm.fields.push({
                id: 'eventAlbum',
                label: 'This is an official event album',
                elem: 'input',
                type: 'checkbox',
                isRequired: null,
                classes: 'sm:col-span-2 flex justify-end flex-row-reverse'
              })
            }

            this.$store.dispatch('setPanel', {
              title: 'Create event album',
              form: this.addEventAlbumForm,
              endpoint: '/album',
              errorMessage: "Sorry we couldn't add the album.",
              successMessage: 'Your album has been added.',
              action: this.$route.path
            })
            break
          case 'video':
            this.$store.dispatch('setPanel', {
              title: 'Add event video',
              form: this.addEventVideoForm,
              endpoint: '/video',
              errorMessage: "Sorry we couldn't add your video.",
              successMessage: 'Your video has been added.',
              action: this.$route.path
            })
            break
          case 'lap-time':
            this.$store.dispatch('setPanel', {
              title: 'Add event lap time',
              form: this.addEventLaptimeForm,
              endpoint: '/laptime',
              errorMessage: "Sorry we couldn't add your lap time.",
              successMessage: 'Your lap time has been added.',
              action: this.$route.path
            })
            break
          default:
            // Reset form prop
            for await (const field of this.updateEventForm.fields) {
              field.children = []
            }

            await Promise.all([
              this.$store.dispatch('circuits/getCircuits'),
              this.getCircuitLayouts(this.updateEventForm),
              this.$store.dispatch('organisers/getOrganisers'),
              this.$store.dispatch('formats/getFormats')
            ])

            this.setCircuits(this.updateEventForm)
            this.setOrganisers(this.updateEventForm)
            this.setFormats(this.updateEventForm)
            this.setMaxDate(this.updateEventForm)

            this.$store.dispatch('setPanel', {
              title: this.actionTitle,
              form: this.updateEventForm,
              method: 'PUT',
              endpoint: `/event/${this.event.uid}`,
              errorMessage: "Sorry we couldn't update the event.",
              successMessage: 'The event has been updated.'
            })
        }
      },
      async getCircuitLayouts(form) {
        try {
          const circuitLayouts = await this.apiRequest(`/circuit/${this.event.circuit_uid}/layouts`, null, null, {
            Authorization: this.userJWT
          })

          const layoutFormField = form.fields.find((field) => field.id === 'layout_uid')

          circuitLayouts.forEach((layout) => {
            layoutFormField.children.push({
              tag: 'option',
              text: layout.name,
              value: layout.layout_uid
            })
          })

          layoutFormField.selected = this.event.layout_uid !== null ? this.event.layout_uid : null
        } catch (e) {
          if (e) console.log(`Error getting layouts: ${e.status} ${e.data}`)
        }
      },
      async setCircuits(form) {
        const circuitFormField = form.fields.find((field) => field.id === 'circuit_uid')

        this.circuits.forEach((circuit) => {
          circuitFormField.children.push({
            tag: 'option',
            text: circuit.name,
            value: circuit.uid
          })
        })
        circuitFormField.selected = this.event.circuit_uid
      },
      async setOrganisers(form) {
        const organiserFormField = form.fields.find((field) => field.id === 'organiser_uid')

        this.organisers.forEach((organiser) => {
          organiserFormField.children.push({
            tag: 'option',
            text: organiser.name,
            value: organiser.uid
          })
        })
        organiserFormField.selected = this.event.organiser_uid
      },
      async setFormats(form) {
        const formatFormField = form.fields.find((field) => field.id === 'format_uid')

        this.formats.forEach((format) => {
          formatFormField.children.push({
            tag: 'option',
            text: format.name,
            value: format.uid
          })
        })
        formatFormField.selected = this.event.format_uid
      },
      async checkAttendingStatus() {
        if (!this.loggedInUser) return false

        this.isAttending = this.members.some((user) => {
          return user.username === this.loggedInUser.username
        })
      },
      async updateAttendingStatus(event) {
        this.isUpdatingStatus = true
        event.target.style.width = `${event.target.offsetWidth}px`

        try {
          await this.apiRequest(
            `/event/${this.event.uid}/user`,
            null,
            null,
            { Authorization: this.userJWT },
            this.isAttending ? 'DELETE' : 'POST'
          )

          this.isAttending = !this.isAttending
        } catch (e) {
          if (e) console.log(`Error updating attending status: ${e.status} ${e.data}`)
        }

        try {
          this.members = await this.apiRequest(`/event/${this.event.uid}/users`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting members: ${e.status} ${e.data}`)
        }

        event.target.style.width = null
        this.isUpdatingStatus = false
      },
      imageLoaded() {
        this.isImageLoaded = true
      },
      async setMaxDate(form) {
        const start_date = form.fields.find((field) => field.id === 'start_date')

        start_date.max = this.loggedInUser.admin ? '2030-12-31' : this.today
      },
      async updateVideoList(videoUid) {
        this.videos = this.videos.filter((video) => {
          return video.uid !== videoUid
        })
      },
      async updateLapList(laptimeUid) {
        this.laptimes = this.laptimes.filter((laptime) => {
          return laptime.uid !== laptimeUid
        })
      },
      async updateMemberFollowing(userUid, isFollowing) {
        const user = this.members.find((user) => user.uid === userUid)

        user.is_following = isFollowing
      },
      async updateCommentList(commentUid) {
        if (commentUid) {
          this.comments = this.comments.filter((comment) => {
            return comment.uid !== commentUid
          })
        } else {
          this.getComments()
        }
      },
      async showVideo(videoUid) {
        let video = this.videos ? this.videos.find((video) => video.uid === videoUid) : null

        if (video)
          this.$store.dispatch('setOverlay', {
            youtube_id: video.youtube_id,
            uid: video.uid,
            video_uid: video.uid,
            url: video.uid,
            liked: video.liked ? video.liked : null,
            owner_uid: video.owner_uid,
            title: `${video.circuit_name} - ${video.layout_name}`,
            content: video.description,
            meta: {
              username: video.username,
              first_name: video.first_name,
              last_name: video.last_name,
              date: video.event_date,
              url: video.event_slug,
              lap_time: video.lap_time
            }
          })
      }
    }
  }
</script>
