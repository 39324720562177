<template>
  <AtomDashHeader :is-loading="isLoading" :title="title" :action-title="actionTitle" @action="buildForm()" />

  <!-- Leaderboards -->
  <div v-if="!isLoading && hasLeaderboards" class="mb-4 lg:mb-8">
    <div class="mt-4 px-4 lg:mt-6 lg:px-8">
      <div class="mx-auto max-w-screen-2xl">
        <AtomLeaderboard
          v-for="leaderboard in leaderboards"
          :key="leaderboard.uid"
          :leaderboard="leaderboard"
          class="mt-4 lg:mt-8"
          @deleted="updateLeaderboardList"
          @update-leaderboards="getUserLeaderboards"
        />
      </div>
    </div>
  </div>

  <!-- Empty State -->
  <div v-if="!isLoading && !hasLeaderboards" class="mt-4 md:px-4 lg:mt-8 lg:px-8">
    <div class="mx-auto max-w-screen-2xl">
      <div class="px-4 text-lg space-y-4 md:px-0">
        <ul class="max-w-screen-sm space-y-2 lg:space-y-4">
          <li class="flex items-start">
            <BaseIcon class="mr-2 transform translate-y-0.5"><CheckIcon /></BaseIcon>
            <p class="flex-1">
              Who's really the quickest driver that you know?
              <button
                class="
                  lg:hover:text-orange-500
                  lg:hover:border-orange-500
                  inline-block
                  leading-normal
                  border-b border-black
                  focus:outline-none
                  focus:shadow-none
                  transition-colors
                  duration-150
                  ease-in-out
                "
                @click.prevent="buildForm()"
              >
                Create a leaderboard
              </button>
              to see which one your mates is the quickest at any circuit.
            </p>
          </li>
          <li class="flex items-start">
            <BaseIcon class="mr-2 transform translate-y-0.5"><CheckIcon /></BaseIcon>
            <p class="flex-1">
              You might have to
              <RouterLink
                class="
                  lg:hover:text-orange-500
                  lg:hover:border-orange-500
                  inline-block
                  leading-normal
                  border-b border-black
                  focus:outline-none
                  focus:shadow-none
                  transition-colors
                  duration-150
                  ease-in-out
                "
                to="/members"
                >find some friends</RouterLink
              >
              first as you can only create leaderboards between friends.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    inject: ['sidebar'],
    data() {
      return {
        title: 'Leaderboards',
        actionTitle: 'Create a leaderboard',
        isLoading: null,
        leaderboards: null,
        events: null,
        form: {
          cta: 'Create leaderboard',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'name',
              label: 'Name',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            // {
            //   id: 'description',
            //   label: 'Description',
            //   elem: 'textarea',
            //   type: 'textarea',
            //   isRequired: null,
            //   classes: 'sm:col-span-2'
            // },
            {
              id: 'users_uid',
              label: 'Friends',
              elem: 'select',
              isRequired: null,
              classes: 'sm:col-span-2',
              multiple: true,
              isRequired: 'true',
              size: 5,
              children: []
            },
            {
              id: 'event_uid',
              label: 'Event',
              elem: 'select',
              isRequired: null,
              classes: 'sm:col-span-2',
              children: [
                {
                  tag: 'option',
                  text: 'None',
                  value: 'none'
                }
              ]
            },
            {
              id: 'circuit_uid',
              label: 'Circuit',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'layout_uid',
              label: 'Layout',
              elem: 'select',
              isRequired: 'true',
              children: [],
              disabled: true
            }
          ]
        },
        jwt: null,
        users: null
      }
    },
    computed: {
      hasLeaderboards() {
        return !this.isLoading && this.leaderboards && !!this.leaderboards.length
        return false
      },
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        loggedInUser: 'auth/getLoggedInUser',
        circuits: 'circuits/getCircuits',
        today: 'getToday'
      })
    },
    watch: {
      async panelStatus(res) {
        if (res) {
          this.loadContent()
          this.$store.dispatch('setPanelStatus', null)
        }
      }
    },
    mounted() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        this.isLoading = true
        await this.getUserLeaderboards()

        if (this.leaderboards && !!this.leaderboards.length) {
          this.leaderboards.forEach((leaderboard) => {
            this.getLeaderboardsLaps(leaderboard)
          })
        }

        if (this.$route.query.p) await this.buildForm()

        this.isLoading = false
      },
      async getUserLeaderboards() {
        try {
          this.leaderboards = await this.apiRequest(`/user/${this.loggedInUser.id}/leaderboards`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log('Error getUserLeaderboards')
        }
      },
      async getLeaderboardsLaps(leaderboard) {
        try {
          const laptimes = await this.apiRequest(
            `/leaderboard/${leaderboard.uid}/laps`,
            null,
            { eventUid: leaderboard.event_uid },
            {
              Authorization: this.userJWT
            }
          )

          const thisLeaderboard = this.leaderboards.findIndex((board) => board.uid === leaderboard.uid)

          if (laptimes) this.leaderboards[thisLeaderboard].laptimes = laptimes.sort((a, b) => a.laptime - b.laptime)
        } catch (e) {
          if (e) console.log('Error getLeaderboardsLaps')
        }
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        await Promise.all([this.$store.dispatch('circuits/getCircuits'), this.getUserEvents(), this.getUserFriends()])

        this.setCircuits()
        this.setEvents()
        this.setFriends()

        if (this.$route.query.p) {
          let queries = JSON.parse(JSON.stringify(this.$route.query))
          queries.p = undefined

          this.$router.replace({ query: queries })
        }

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          endpoint: '/leaderboard',
          errorMessage: "Sorry we couldn't create your leaderboard.",
          successMessage: 'Your leaderboard has been created.'
        })
      },
      async getUserEvents() {
        try {
          this.events = await this.apiRequest(
            `/user/${this.loggedInUser.id}/events`,
            null,
            { basic: true, start: '2000-01-01', end: this.today },
            {
              Authorization: this.userJWT
            }
          )
        } catch (e) {
          if (e) console.log('Error getUserEvents')
        }
      },
      async getUserFriends() {
        try {
          this.users = await this.apiRequest(`/user/${this.loggedInUser.id}/following`)

          this.users = this.users.filter((member) => {
            return member.first_name != null && member.last_name != null
          })
        } catch (e) {
          if (e) console.log(`Error getUserFriends`)
        }
      },
      async setEvents() {
        if (!this.events) return

        const eventFormField = this.form.fields.find((field) => field.id === 'event_uid')
        const pastEvents = this.events.filter((event) => {
          return Date.parse(event.start_date) <= Date.now() ? event : null
        })
        const re = /(\d{4})[-. \/](\d\d)[-. \/](\d\d)/

        pastEvents.forEach((event) => {
          const date = event.start_date.slice(0, 10).replace(re, '$3/$2/$1')
          let text = `${date} - ${event.circuit} `

          if (event.slug.includes('evening')) text += `- Evening `
          text += `(${event.organiser})`

          eventFormField.children.push({
            tag: 'option',
            text,
            value: event.uid
          })
        })
      },
      async setFriends() {
        const usersFormField = this.form.fields.find((field) => field.id === 'users_uid')

        usersFormField.children.push({
          tag: 'option',
          text: `You (${this.loggedInUser.username})`,
          value: this.loggedInUser.id
        })

        this.users.forEach((user) => {
          usersFormField.children.push({
            tag: 'option',
            text: `${user.first_name} ${user.last_name} (${user.username})`,
            value: user.uid
          })
        })
      },
      async setCircuits() {
        const circuitFormField = this.form.fields.find((field) => field.id === 'circuit_uid')

        this.circuits.forEach((circuit) => {
          circuitFormField.children.push({
            tag: 'option',
            text: circuit.name,
            value: circuit.uid
          })
        })
      },
      async updateLeaderboardList(leaderboardUid) {
        this.leaderboards = this.leaderboards.filter((leaderboard) => {
          return leaderboard.uid !== leaderboardUid
        })
      }
    }
  }
</script>
